import React from "react"
import styled from "@emotion/styled"
import { Box, Button, Grid } from "kui-basic"
import { useNavigate } from "react-router-dom"
import _ from "lodash"
import { TrashIcon, CloudIcon } from "kui-icon"
import { useToggle } from "kui-utils"
import { InspectionTypes, PageBreadcrumbs } from "kui-crm"
import Container from "../../../../../../components/ui/Container"
import ConfirmationModal from "../../../../../../components/common/ConfirmationModal"
import { Routes } from "../../../../../../types/route"
import useInspectionStore from "../../store"

const getInspectionsListTitle = (type?: InspectionTypes | null) => {
  if (type === "appraisal") return "Appraisals"
  if (type === "impairments") return "Impairments"
  return "Inspections"
}

const getInspectionsListLink = (
  apartmentId: number | null,
  type?: InspectionTypes | null
) => {
  const apartmentInspectionsLink = `${Routes.apartments}/${apartmentId}?tab=inspections`

  if (type === "appraisal")
    return `${apartmentInspectionsLink}&subtab=appraisals`
  if (type === "impairments")
    return `${apartmentInspectionsLink}&subtab=impairments`
  return apartmentInspectionsLink
}

const InspectionHeader = () => {
  const [isOpenDeleteModal, handleDeleteModalOpen, handleDeleteModalClose] =
    useToggle()
  const navigate = useNavigate()
  const { inspectionInfo, type, apartmentStore, deleteInspection } =
    useInspectionStore()
  const apartmentId = apartmentStore.overviewStore.id
  const title = getInspectionsListTitle(type)
  const inspectionLabel = `${_.capitalize(type || "")}${
    type !== "appraisal" && type !== "impairments" ? " inspection" : ""
  } ${inspectionInfo?.date?.toFormat("dd.MM.yyyy") || ""}`
  const link = getInspectionsListLink(apartmentId, type)

  const handleDelete = async () => {
    await deleteInspection()
    navigate(link)
  }

  const handlePrevClick = () => {
    navigate(link)
  }

  const breadcrumbs = [
    { link, title },
    { link: null, title: inspectionLabel },
  ]

  return (
    <StyledWrapper>
      <StyledHeader>
        <Container>
          <Grid container justify="space-between" alignItems="center">
            <PageBreadcrumbs
              onPrev={handlePrevClick}
              breadcrumbs={breadcrumbs}
            />
            <Grid item>
              <Grid container alignItems="center" justify="flex-end">
                <Box mr={2}>
                  <Button isCircle size="xs" variant="orange">
                    <CloudIcon />
                  </Button>
                </Box>
                <Button
                  isCircle
                  size="xs"
                  variant="whiteWithGray"
                  onClick={handleDeleteModalOpen}
                >
                  <TrashIcon />
                </Button>
              </Grid>
            </Grid>
          </Grid>
        </Container>
      </StyledHeader>

      <ConfirmationModal
        open={isOpenDeleteModal}
        handleClose={handleDeleteModalClose}
        handleSubmit={handleDelete}
      />
    </StyledWrapper>
  )
}

export default InspectionHeader

const StyledWrapper = styled.div`
  position: sticky;
  top: 170px;
  z-index: 3;
`

const StyledHeader = styled.div`
  padding: 12px 0;
  background: white;
`
