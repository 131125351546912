import React from "react"
import { Button, Grid } from "kui-basic"
import { observer } from "mobx-react"
import useExpensesStore from "../../../../store"

const CalcDepositFileButton = () => {
  const { summaryStore, overviewStore, periodsStore } = useExpensesStore()
  const { selectedPeriod } = periodsStore
  const disabled = !(
    selectedPeriod?.isLastMonthOfContract &&
    !selectedPeriod?.isDepositCalculated
  )

  const handleClick = () => {
    if (overviewStore.id && selectedPeriod?.id) {
      summaryStore.calcDepositAndRefresh(
        overviewStore.id,
        selectedPeriod?.rentalContract?.id,
        selectedPeriod.id
      )
    }
  }

  return (
    <Grid item>
      <Button
        disabled={disabled}
        variant="white"
        size="s"
        onClick={handleClick}
      >
        Deposit calculation
      </Button>
    </Grid>
  )
}

export default observer(CalcDepositFileButton)
